<template>
  <calendar />
</template>

<script>
import Calendar from "../components/Calendar.vue";

export default {
  name: "Home",

  components: {
    Calendar,
  },
};
</script>
